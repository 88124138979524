import React, { memo } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import Grid from "@material-ui/core/Grid";

import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import styles from "./styles";
import _ from "lodash";
const useStyles = makeStyles(styles);

const CustomTable = ({
  headers,
  contents,
  type = "table",
  leftText = { 0: true },
  style = {},
  selectedRow = [],
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const computer = useMediaQuery(theme.breakpoints.up("sm"));
  let titleClass = classes.tableTitle;
  let valueClass = classes.tableValue;
  if (type == "outlined") {
    titleClass = classes.outlinedTitle;
    valueClass = classes.outlinedValue;
  }
  if (type == "column") {
    titleClass = classes.columnTitle;
    valueClass = classes.columnValue;
  }
  if (computer) {
    return (
      <TableContainer className={classes.card}>
        <Table
          style={{
            borderCollapse: "separate",
            borderSpacing: "0 1em",
          }}
        >
          <TableHead>
            <TableRow>
              {headers.map((header, index) => {
                return (
                  <TableCell
                    key={index}
                    align={leftText[index] == true ? "left" : "center"}
                    className={`${classes.title} ${titleClass}`}
                  >
                    {header}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {_.map(contents, (row, index) => (
              <TableRow
                key={index}
                style={{
                  borderRadius: 10,
                  marginBottom: 10,
                }}
              >
                {row &&
                  row.map((r, rIndex) => (
                    <TableCell
                      key={rIndex}
                      align={leftText[rIndex] == true ? "left" : "center"}
                      className={`${classes.value} ${valueClass} ${
                        selectedRow.includes(index) ? classes.selected : ""
                      } `}
                      style={{ ...style }}
                    >
                      {r}
                    </TableCell>
                  ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  } else {
    return (
      <TableContainer className={classes.card}>
        <Table
          style={{
            borderCollapse: "separate",
            borderSpacing: "0 1em",
          }}
        >
          <TableBody>
            {_.map(contents, (row, index) => (
              <TableRow
                key={index}
                style={{
                  borderRadius: 10,
                  marginBottom: 10,
                }}
              >
                <TableCell
                  className={`${classes.value} ${valueClass} ${
                    selectedRow.includes(index) ? classes.selected : ""
                  }`}
                  style={{ ...style }}
                >
                  {row &&
                    row.map((r, rIndex) => {
                      return (
                        <Grid container style={{ lineHeight: 2 }} key={rIndex}>
                          <Grid item xs={5}>
                            {headers[rIndex]}
                          </Grid>{" "}
                          <Grid item xs={7}>
                            {r}
                          </Grid>
                        </Grid>
                      );
                    })}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
};

export default memo(CustomTable);
