import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Background from "assets/img/grass.png";
import styles from "./styles";
import discordIcon from "assets/img/discord.png";
import twitterIcon from "assets/img/twitter.png";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles(styles);

const Footer = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const computer = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <div
      style={{
        height: 500,
        background: `url(${Background})`,
        backgroundPosition: "top",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        color: "white",
        textAlign: "center",
        fontSize: computer ? 50 : 20,
        position: "relative",
      }}
    >
      <div
        style={{
          position: "absolute",
          bottom: 0,
          left: "50%",
          transform: "translate(-50%,-50%)",
        }}
      >
        <div>
          <div>
            Find us <span style={{ fontSize: 20 }}>AT</span>
          </div>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              window.open("https://www.cryptounicorns.fun", "_blank");
            }}
          >
            https://www.cryptounicorns.fun
          </div>
          <div>
            <a
              href="https://twitter.com/crypto_unicorns"
              target="_blank"
              className={classes.itemLink}
            >
              <img src={twitterIcon} className="icon" />
            </a>

            <a
              href="http://discord.gg/cryptounicorns"
              target="_blank"
              className={classes.itemLink}
            >
              <img src={discordIcon} className="icon" />
            </a>
            <div style={{ fontSize: 20 }}>
              Powered by{" "}
              <a href="https://vault.inc" target="_blank">
                Vault.inc
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Footer);
