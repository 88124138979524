import React, { useState } from "react";
import {
  useFetchPoolDetails,
} from "features/home/redux/hooks";
import Leaderboard from "./Leaderboard";
import _ from "lodash";
import Tabs from "components/CustomTabs/Tabs.js";

const NewLeaderboard = () => {
  const [tabValue, setTabValue] = useState(0);
  const { setSelctedPool } = useFetchPoolDetails();
  const handleTabChange = (event, newValue) => {
    setSelctedPool(newValue)
    setTabValue(newValue);
  };
  return (
    <div style={{ maxWidth: 1100, margin: "0 auto" }} className="card">
      
      <Tabs
        tabIndex={tabValue}
        handleChange={handleTabChange}
        tabs={[
          {
            label: "RBW",
            content: <Leaderboard type="single" />,
          },
          {
            label: "RBWLP",
            content: <Leaderboard type="lp" />,
          },
        ]}
      />
    </div>
  );
};

export default NewLeaderboard;
