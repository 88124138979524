export const tokens = {
  rewardToken: {
    token: "RBW",
    address: "0x431CD3C9AC9Fc73644BF68bF5691f4B83F9E104f",
    decimals: 18,
  },
};
export const activeBadgeList = {
  0: [
    {
      boostFactor: 0.125,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "69",
    },
    {
      boostFactor: 0.075,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "68",
    },
    {
      boostFactor: 0.05,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "67",
    },
    {
      boostFactor: 0.125,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "53",
    },
    {
      boostFactor: 0.05,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "50",
    },
    {
      boostFactor: 0.125,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "49",
    },
    {
      boostFactor: 0.075,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "48",
    },
    {
      boostFactor: 0.05,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "47",
    },
    {
      boostFactor: 0.125,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "45",
    },
    {
      boostFactor: 0.075,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "44",
    },
    {
      boostFactor: 0.05,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "43",
    },
    {
      boostFactor: 0.2,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "41",
    },
    {
      boostFactor: 0.125,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "84",
    },
    {
      boostFactor: 0.125,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "100",
    },
    {
      boostFactor: 0.075,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "99",
    },
    {
      boostFactor: 0.05,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "98",
    },
    {
      boostFactor: 0.05,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "101",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "85",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "86",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "87",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "88",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "89",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "90",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "91",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "92",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "93",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "94",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "95",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "96",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "97",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "115",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "116",
    },
    {
      boostFactor: 0.2,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "106",
    },
    {
      boostFactor: 0.075,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "107",
    },
    {
      boostFactor: 0.125,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "103",
    },
    {
      boostFactor: 0.125,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "104",
    },
    {
      boostFactor: 0.125,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "105",
    },
    {
      boostFactor: 0.125,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "102",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "119",
    },
    {
      boostFactor: 0.05,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "117",
    },
    {
      boostFactor: 0.05,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "111",
    },
    {
      boostFactor: 0.05,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "112",
    },
    {
      boostFactor: 0.075,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "113",
    },
    {
      boostFactor: 0.125,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "114",
    },
    {
      boostFactor: 0.125,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "118",
    },
    {
      boostFactor: 0.05,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "121",
    },
    {
      boostFactor: 0.05,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "122",
    },
    {
      boostFactor: 0.075,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "123",
    },
    {
      boostFactor: 0.125,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "124",
    },
    {
      boostFactor: 0.05,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "125",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "126",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "127",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "127",
    },
    {
      boostFactor: 0.2,
      tokenId: "139",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "138",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "137",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "136",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "135",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "134",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "133",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "132",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "131",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "130",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "129",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "128",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "140",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "141",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "142",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "143",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "144",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "145",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "146",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "147",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.075,
      tokenId: "148",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "149",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "150",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "151",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "152",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "153",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "155",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "156",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "157",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "158",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "160",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "161",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "162",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "163",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "164",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "165",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.075,
      tokenId: "166",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "167",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "168",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "169",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "170",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.075,
      tokenId: "171",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "172",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "173",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "174",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "175",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "176",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "177",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "178",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "179",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "180",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "181",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "182",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "183",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "184",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "185",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "186",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.2,
      tokenId: "187",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "188",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "189",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "190",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "191",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "192",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "193",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "194",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "195",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "196",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
  ],
  1: [
    {
      boostFactor: 0.125,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "69",
    },
    {
      boostFactor: 0.075,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "68",
    },
    {
      boostFactor: 0.05,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "67",
    },
    {
      boostFactor: 0.125,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "53",
    },
    {
      boostFactor: 0.05,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "50",
    },
    {
      boostFactor: 0.125,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "49",
    },
    {
      boostFactor: 0.075,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "48",
    },
    {
      boostFactor: 0.05,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "47",
    },
    {
      boostFactor: 0.125,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "45",
    },
    {
      boostFactor: 0.075,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "44",
    },
    {
      boostFactor: 0.05,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "43",
    },
    {
      boostFactor: 0.2,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "41",
    },
    {
      boostFactor: 0.125,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "84",
    },
    {
      boostFactor: 0.125,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "100",
    },
    {
      boostFactor: 0.075,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "99",
    },
    {
      boostFactor: 0.05,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "98",
    },
    {
      boostFactor: 0.05,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "101",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "85",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "86",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "87",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "88",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "89",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "90",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "91",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "92",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "93",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "94",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "95",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "96",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "97",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "115",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "116",
    },
    {
      boostFactor: 0.2,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "106",
    },
    {
      boostFactor: 0.075,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "107",
    },
    {
      boostFactor: 0.125,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "103",
    },
    {
      boostFactor: 0.125,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "104",
    },
    {
      boostFactor: 0.125,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "105",
    },
    {
      boostFactor: 0.125,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "102",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "119",
    },
    {
      boostFactor: 0.05,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "117",
    },
    {
      boostFactor: 0.05,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "111",
    },
    {
      boostFactor: 0.05,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "112",
    },
    {
      boostFactor: 0.075,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "113",
    },
    {
      boostFactor: 0.125,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "114",
    },
    {
      boostFactor: 0.125,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "118",
    },
    {
      boostFactor: 0.05,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "121",
    },
    {
      boostFactor: 0.05,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "122",
    },
    {
      boostFactor: 0.075,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "123",
    },
    {
      boostFactor: 0.125,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "124",
    },
    {
      boostFactor: 0.05,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "125",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "126",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "127",
    },
    {
      boostFactor: 0.2,
      tokenId: "139",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "138",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "137",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "136",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "135",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "134",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "133",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "132",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "131",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "130",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "129",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "128",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "140",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "141",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "142",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "143",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "144",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "145",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "146",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "147",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.075,
      tokenId: "148",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "149",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "150",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "151",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "152",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "153",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "155",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "156",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "157",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "158",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "160",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "161",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "162",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "163",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "164",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "165",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.075,
      tokenId: "166",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "167",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "168",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "169",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "170",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.075,
      tokenId: "171",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "172",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "173",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "174",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "175",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "176",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "177",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "178",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "179",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "180",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "181",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "182",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "183",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "184",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "185",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "186",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.2,
      tokenId: "187",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "188",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "189",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "190",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "191",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "192",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "193",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "194",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "195",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "196",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
  ],
};
