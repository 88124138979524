const styles = (theme) => ({
  title: {
    fontSize: "1.2rem",
    fontWeight: 500,
    paddingBottom: 10,

    paddingTop: 10,
    color: "#BDB8B8",
    // "&:first-child": {
    //   borderTopLeftRadius: 10,
    //   borderBottomLeftRadius: 10,
    // },
    // "&:last-child": {
    //   borderTopRightRadius: 10,
    //   borderBottomRightRadius: 10,
    // },
  },
  tableTitle: {
    backgroundColor: "transparent",
    borderBottom: `0px solid ${theme.palette.text.white}`,
  },
  outlinedTitle: {
    border: `1px solid ${theme.palette.text.white}`,
  },

  columnTitle: {
    borderBottom: `1px solid ${theme.palette.text.white}`,
    borderRight: `1px solid ${theme.palette.text.white}`,
  },
  lgValue: {
    fontSize: "50px",
  },
  value: {
    fontSize: "25px",
    fontWeight: 500,
    color: theme.palette.text.white,
    backgroundColor: "transparent",
    "&:first-child": {
      borderTopLeftRadius: 10,
      borderBottomLeftRadius: 10,
    },
    "&:last-child": {
      borderTopRightRadius: 10,
      borderBottomRightRadius: 10,
    },
  },

  card: {
    flexGrow: 1,
    backgroundColor: "transparent",
    overflow: "auto",
    borderRadius: 5,
    marginTop: 10,
  },
  tableValue: {
    borderBottom: `0px solid ${theme.palette.text.white}`,
    padding: "10px",
  },
  outlinedValue: {
    border: `0px solid ${theme.palette.text.white}`,
    padding: 10,
  },
  columnValue: {
    borderRight: `0px solid ${theme.palette.text.white}`,
    borderBottom: "none",
  },
  selected: {
    backgroundColor: "rgb(41,76,52)",
  },
});

export default styles;
