import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { removeSnackbar } from './redux/actions';
// import Button from '@material-ui/core/Button';
// import styled from 'styled-components'
let displayed = [];

const Notifier = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(store => store.common.notifications || []);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = id => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = id => {
    displayed = [...displayed.filter(key => id !== key)];
  };

  React.useEffect(() => {
    notifications.forEach(({ key, message, options = {}, dismissed = false, hash }) => {
      if (dismissed) {
        // dismiss snackbar using notistack
        closeSnackbar(key);
        return;
      }

      // do nothing if snackbar is already displayed
      if (displayed.includes(key)) return;

      // display snackbar using notistack
      enqueueSnackbar(message, {
        key,
        ...options,
        onClose: (event, reason, myKey) => {
          if (options.onClose) {
            options.onClose(event, reason, myKey);
          }
        },
        onExited: (event, myKey) => {
          // remove this snackbar from redux store
          dispatch(removeSnackbar(myKey));
          removeDisplayed(myKey);
        },
        autoHideDuration: 3000,
        action: hash ? () => <a style={{ 'fontWeight': 'bold', 'backgroundColor': '#97eac9', 'borderRadius': 10, 'padding': '12px 12px', color: 'black' }} onClick={() => window.open(`https://polygonscan.com/tx/${hash}`, '_blank')}>VIEW</a> : null,
      });

      // keep track of snackbars that we've displayed
      storeDisplayed(key);
    });
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);

  return null;
};

// const StyledLink = styled.a`
//   font-weight: bold;
//   background-color: #97eac9;
//   border-radius: 10px;
//   padding: 6px 12px;
//   color: black;
// `
export default Notifier;
