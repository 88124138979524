import { grayColor } from "assets/jss/material-kit-pro-react.js";
const styles = (theme) => ({
  button: {
    minHeight: "auto",
    minWidth: "auto",
    backgroundColor: `${theme.palette.text.green}`,
    color: `white`,
    textTransform: "none",
    // border: `1px solid ${theme.palette.text.green}`,
    height: "36px",

    textShadow: "0.1em 0.1em 0.2em #333",
    borderRadius: "8px",
    position: "relative",
    padding: "10px 10px",
    margin: 10,
    fontSize: "17px",
    letterSpacing: "0",
    // willChange: 'box-shadow, transform',
    // transition:
    //   'box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
    lineHeight: "1.42857143",
    textAlign: "center",
    whiteSpace: "nowrap",
    verticalAlign: "middle",
    // boxShadow: "3px 3px 3px 3px rgba(0, 0, 0, 0.2)",
    // touchAction: 'manipulation',
    cursor: "pointer",
    "& .MuiButton-label": {
      // color: `${theme.palette.text.green}`,
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      position: "relative",
      display: "inline-block",
      top: "0",
      marginTop: "-1em",
      marginBottom: "-1em",
      fontSize: "1.1rem",
      marginRight: "4px",
      verticalAlign: "middle",
    },
    "& svg": {
      position: "relative",
      display: "inline-block",
      top: "0",
      width: "18px",
      height: "18px",
      marginRight: "4px",
      verticalAlign: "middle",
    },
    "&:hover": {
      backgroundColor: `${theme.palette.text.green}`,
      "& .MuiButton-label": {
        color: "black",
      },
    },
    "&$justIcon": {
      "& .fab,& .fas,& .far,& .fal,& .material-icons": {
        marginTop: "0px",
        marginRight: "0px",
        position: "absolute",
        width: "100%",
        transform: "none",
        left: "0px",
        top: "0px",
        height: "100%",
        lineHeight: "41px",
        fontSize: "20px",
      },
    },
  },
  fullWidth: {
    width: "100%",
    margin: 0,
  },
  blueOutlined: {
    backgroundColor: "transparent",
    border: `1px solid ${theme.palette.text.green}`,
    color: `${theme.palette.text.green}`,
    "& .MuiButton-label": {
      color: `${theme.palette.text.green}`,
    },
    "&:hover": {
      backgroundColor: `${theme.palette.text.green}`,
      "& .MuiButton-label": {
        color: "black",
      },
    },
  },
  grey: {
    background: "#BDB8B8",
    fontSize: "1.15rem",
    height: "50px",
    borderRadius: "42px",
    "& .MuiButton-label": {
      color: "white",
    },
    "&:hover,&:focus": {
      "& .MuiButton-label": {
        color: "white",
      },
      background:
        "radial-gradient(50% 50% at 50% 50%, #FFD77A 3.65%, #FF9A01 100%)",
    },
  },
  primary: {
    background: "linear-gradient(0.25turn, rgb(24,106,202), rgb(152, 205, 245), rgb(24,106,202))",
    border: "2px solid rgb(152, 205, 245)",
    fontSize: "1.3rem",
    height: "48px",
    "& .MuiButton-label": {
      color: "white",
    },
    "&:hover,&:focus": {
      "& .MuiButton-label": {
        color: "white",
      },
      background:
        "linear-gradient(0.25turn, rgb(24,106,202), rgb(152, 205, 245), rgb(24,106,202))",
    },
  },
  secondary: {
    background: "linear-gradient(0.25turn, rgb(24,106,202), rgb(152, 205, 245), rgb(24,106,202))",
    border: "4px solid #59AEFF",
    margin: 5,
    minWidth: 180,
    fontSize: "1.15rem",
    borderRadius: "10px",
    height: "48px",
    "& .MuiButton-label": {
      color: `white`,
      // textShadow: "0px 4px 0px #144C92",
    },
    "&:hover,&:focus": {
      "& .MuiButton-label": {
        color: "white",
      },
      background: "linear-gradient(0.25turn, rgb(24,106,202), rgb(152, 205, 245), rgb(24,106,202))",
    },
  },
  selected: {
    background: `url(${
      require("assets/img/object.png").default
    }),linear-gradient(0deg, #FFD77A -82.19%, #FF9A01 106.79%)`,
    border: "4px solid #FA961D",
  
    margin: 5,
    minWidth: 180,
    fontSize: "1.15rem",
    borderRadius: "10px",
    backgroundSize: "cover",
    height: "48px",

    "& .MuiButton-label": {
      color: `white`,
      textShadow: "0px 4px 0px #B44007",
    },
    "&:hover,&:focus": {
      "& .MuiButton-label": {
        color: "white",
      },
      background: `url(${
        require("assets/img/object.png").default
      }),linear-gradient(0deg, #FFD77A -82.19%, #FF9A01 106.79%)`,
      backgroundSize: "cover",
    },
  },
  wallet: {
    background: "linear-gradient(0deg, #FFD77A -82.19%, #FF9A01 106.79%)",
    border: "4px solid #FA961D",
    margin: 5,
    minWidth: 220,
    fontSize: "23px",
    borderRadius: 10,
    height: "50px",
    "& .MuiButton-label": {
      color: `white`,
      textShadow: "0px 4px 0px #B44007",
    },
    "&:hover,&:focus": {
      "& .MuiButton-label": {
        color: "white",
      },
      background: "linear-gradient(0deg, #FFD77A -82.19%, #FF9A01 106.79%)",
    },
  },
  text: {
    border: `none`,
    textDecoration: "underline",
    textDecorationColor: theme.palette.text.green,
    fontSize: "0.95rem",
    fontWeight: 900,
    "& .MuiButton-label": {
      color: theme.palette.text.green,
    },
    "&:hover,&:focus": {
      "& .MuiButton-label": {
        color: theme.palette.text.green,
      },
      backgroundColor: "transparents",
    },
  },
  info: {
    backgroundColor: "transparent",
    borderRadius: 20,
    padding: "0px 20px",
    border: `1px solid ${theme.palette.text.primary}`,
    "& .MuiButton-label": {
      color: theme.palette.text.primary,
    },
    "&:hover,&:focus": {
      "& .MuiButton-label": {
        color: theme.palette.text.primary,
      },
      backgroundColor: "transparent",
    },
  },
  transparent: {
    "&,&:focus,&:hover": {
      color: "inherit",
      background: "transparent",
      boxShadow: "none",
    },
  },
  disabled: {
    opacity: "0.65",
    textDecoration: "none",
    "& .MuiButton-label": {
      color: theme.palette.text.primary,
    },
    border: "none",
    backgroundColor: "rgb(221, 221, 221)",

    pointerEvents: "none",
  },
  lg: {
    "&$justIcon": {
      "& .fab,& .fas,& .far,& .fal,& svg,& .material-icons": {
        marginTop: "-4px",
      },
    },
    height: "54px",
    padding: "1.125rem 2.25rem",
    fontSize: "1.3rem",
    lineHeight: "1.333333",
  },
  sm: {
    "&$justIcon": {
      "& .fab,& .fas,& .far,& .fal,& svg,& .material-icons": {
        marginTop: "1px",
      },
    },
    padding: "0.3rem 0.5rem",
    height: 30,
    fontSize: "0.9rem",
    lineHeight: "1.5",
    borderRadius: "0.2rem",
  },
  round: {
    borderRadius: "30px",
  },
  block: {
    width: "100% !important",
  },
  link: {
    "&,&:hover,&:focus": {
      backgroundColor: "transparent",
      color: grayColor[0],
      boxShadow: "none",
    },
  },
  justIcon: {
    paddingLeft: "12px",
    paddingRight: "12px",
    borderRadius: "28px",
    fontSize: "20px",
    height: "56px",
    minWidth: "41px",
    width: "41px",
    "& .fab,& .fas,& .far,& .fal,& svg,& .material-icons": {
      marginRight: "0px",
    },
    "&$lg": {
      height: "57px",
      minWidth: "57px",
      width: "57px",
      lineHeight: "56px",
      "& .fab,& .fas,& .far,& .fal,& .material-icons": {
        fontSize: "32px",
        lineHeight: "56px",
      },
      "& svg": {
        width: "32px",
        height: "32px",
      },
    },
    "&$sm": {
      height: "30px",
      minWidth: "30px",
      width: "30px",
      "& .fab,& .fas,& .far,& .fal,& .material-icons": {
        fontSize: "17px",
        lineHeight: "29px",
      },
      "& svg": {
        width: "17px",
        height: "17px",
      },
    },
  },
});

export default styles;
