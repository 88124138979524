import React, { useState, useEffect } from "react";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import { Grid, Popover } from "@material-ui/core";
import { useConnectWallet } from "features/home/redux/hooks";

import CustomTable from "components/CustomTable/CustomTable.js";
import Pagination from "@material-ui/lab/Pagination";
import _, { isNumber } from "lodash";
import Stake from "features/stake/Stake.js";
import moment from "moment";
import CustomOutlinedInput from "components/CustomOutlinedInput/CustomOutlinedInput";
import snapshot from "../../assets/data/leaderboard.json";
import Tabs from "components/CustomTabs/Tabs.js";
import BigNumber from "bignumber.js";
import { useFetchLeaderboardDetail } from "./redux/hooks";
import { links, tokens, pools } from "features/configure";
import { convertAmountFromRawNumber } from "../helpers/bignumber";

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 700,
    fontSize: 40,
    lineHeight: 1.1,
    color: "white",
  },
  dateText: {
    fontWeight: 700,
    fontSize: 34,
  },
  bidInfo: {
    backgroundColor: "rgba(255,255,255,0.2)",
    textAlign: "right",
    marginTop: 30,
    padding: 20,
    fontSize: 44,
    lineHeight: 1.1,
    fontWeight: 700,
  },
  bidSpan: {
    fontSize: 24,
    fontWeight: 500,
  },
  bidField: {
    backgroundColor: "#1E2025",
    marginTop: 50,
    padding: 20,
  },
  card: {
    flexGrow: 1,
    maxWidth: 400,
    verticalAlign: "middle",
    backgroundColor: "#1E2025",
    overflow: "hidden",
    borderRadius: 10,
    margin: "0 auto",
    marginTop: 10,
    marginRight: 10,
    padding: "10px 20px",
    fontSize: 18,
  },
  cardSubTitle: {
    fontSize: 14,
    marginTop: 5,
  },
  timeSpan: {
    fontSize: 28,
    marginLeft: "5px",
  },
  time: {
    fontSize: 32,
    marginLeft: "5px",
  },
  tooltip: {
    backgroundColor: "rgba(255,255,255,0.5)",
    margin: "0 10px",
    padding: 3,
    textAlign: "center",
    verticalAlign: "middle",
  },

  grayText: {
    color: "rgba(255,255,255,0.6)",
  },
  heading: {
    fontSize: 20,
    color: "rgba(255,255,255,0.6)",
    textAlign: "right",
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  yourRank: {
    marginLeft: 20,
    background:
      "radial-gradient(117.57% 117.57% at 50% 50%, #55A9FB 0%, #1C65C0 100%)",
    borderRadius: 15,
    border: "4px solid #59AEFF",
    padding: "15px 20px",
    textAlign: "center",
    height: 90,
    width: 90,
  },
  rank: {
    background: "#486EA4",
    borderRadius: 80,
    padding: "5px 20px",
    textAlign: "center",
    height: 85,
    width: 85,
  },
  sm: {
    height: 70,
    width: 70,
    padding: "20px 10px",
  },
  popPaper: {
    backgroundColor: theme.palette.background.paper,
    padding: 10,
  },
  popover: {
    pointerEvents: "none",
  },
}));

const Leaderboard = ({ type }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [search, setSearch] = useState("");
  const { web3, address } = useConnectWallet();
  const [limit, setLimit] = useState(10000);
  const [page, setPage] = React.useState(1);
  const [userRank, setUserRank] = useState("-");
  const [anchorEl, setAnchorEl] = React.useState({});
  const handlePopoverOpen = (event, name, amount) => {
    setAnchorEl({ target: event.currentTarget, name, amount });
  };
  const open = Boolean(anchorEl.target);
  const handlePopoverClose = () => {
    setAnchorEl({});
  };

  const handleChange = (event, value) => {
    setPage(value);
  };
  // const { fetchPrice, priceData } = useFetchPrice();
  const { fetchLeaderboardDetail, fetchLeaderboardDetailPending, detail } =
    useFetchLeaderboardDetail();

  const name = "cryptounicorn";
  useEffect(() => {
    if (web3 && address) {
      fetchLeaderboardDetail({ name, type, page, search, address });
      // fetchPrice({web3});
      const id = setInterval(() => {
        fetchLeaderboardDetail({ name, type, page, search, address });
        // fetchPrice({web3});
      }, 10000);
      return () => clearInterval(id);
    }
  }, [web3, address, name, page, search]);

  useEffect(() => {
    if (detail.selfData && detail.selfData[0]) {
      setUserRank(detail.selfData[0].rank);
      setLimit(parseInt(detail.count) + 100);
    } else {
      setUserRank("-");
    }
  }, [detail]);

  const leaderboard = detail.users ? detail.users : [];

  const listData = leaderboard;

  const changeInputValue = (event) => {
    let value = event.target.value;
    setSearch(value);
  };
  const renderRewardIcon = (r) => {
    return (
      <img
        src={r.image}
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={(event) => handlePopoverOpen(event, r.name, r.amount)}
        onMouseLeave={handlePopoverClose}
        className="icon"
      />
    );
  };
  const renderReward = (r) => {
    if (r == 1) {
      return [
        renderRewardIcon({
          name: "HORSE",
          image: require("assets/img/horse1.svg").default,
          amount: 1,
        }),
        renderRewardIcon({
          name: "HORSE2",
          image: require("assets/img/horse2.svg").default,
          amount: 1,
        }),
        renderRewardIcon({
          name: "ITEM",
          image: require("assets/img/item1.svg").default,
          amount: 1,
        }),
      ];
    }

    if (r == 2) {
      return [
        renderRewardIcon({
          name: "HORSE",
          image: require("assets/img/horse1.svg").default,
          amount: 1,
        }),
      ];
    }

    if (r == 3) {
      return [
        renderRewardIcon({
          name: "HORSE",
          image: require("assets/img/horse1.svg").default,
          amount: 1,
        }),
      ];
    }
  };

  return (
    <>
      <div
        style={{
          position: "relative",
          margin: "0 auto",
          marginTop: "-44px",
          maxWidth: 1100,
          minHeight: "100vh",
        }}
      >
        <Popover
          id="mouse-over-popover"
          className={classes.popover}
          classes={{
            paper: classes.popPaper,
          }}
          open={open}
          anchorEl={anchorEl.target}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          {anchorEl.name} : {anchorEl.amount}
        </Popover>
        <Grid container spacing={2}>
          <Grid xs={12} md={6} />
          <Grid xs={12} md={6}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CustomOutlinedInput
                startAdornment={
                  <img
                    src={require("assets/img/search.svg").default}
                    style={{
                      objectFit: "contain",
                    }}
                  />
                }
                value={search}
                placeholder="SEARCH ADDRESS..."
                onChange={changeInputValue}
              />

              <div className={classes.yourRank}>
                <span style={{ fontSize: 14 }}>You</span>{" "}
                <div style={{ fontSize: 20, fontWeight: 700 }}>
                  # {userRank}
                </div>
              </div>
            </div>
          </Grid>

          <Grid xs={12} item style={{ marginBottom: 20 }}>
            <div className="card">
            <h1>Coming Soon!</h1>
              {/* {!search && (
                <Pagination
                  count={parseInt(limit / 100)}
                  color="primary"
                  page={page}
                  onChange={handleChange}
                  showFirstButton
                  showLastButton
                  defaultPage={6}
                  boundaryCount={2}
                />
              )}
    
              <CustomTable
                leftText={{}}
                headers={[
                  "Rank",
                  `Address`,
                  "Staked Amount",
                  "Points",
                  "Reward",
                ]}
                contents={listData.map((row, index) => {
                  const contributed = `${new BigNumber(
                    parseFloat(row.totalDeposit)
                  ).toFormat(2)}`;
                  return [
                    <div
                      className={`${classes.rank} ${classes.sm}`}
                    >{`#${row.rank}`}</div>,
                    `${row.address.slice(0, 7)}...${row.address.slice(-4)}`,
                    `${contributed}`,
                    `${new BigNumber(row.badges).toFormat(2)}`,
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      {renderReward(row.rank)}
                    </div>,
                  ];
                })}
              /> */}
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Leaderboard;
