import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { AppBar, Tabs, Tab, Box } from "@material-ui/core";
import PropTypes from "prop-types";
import useMediaQuery from "@material-ui/core/useMediaQuery";
function TabPanel(props) {
  const { children, value, index, noHeight, computer, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      style={{
        zIndex: -1,
        marginTop: noHeight ? (computer ? "-70px" : 0) : 0,
      }}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div style={{ marginTop: 10 }}>{children}</div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "transparent",
    overflow: "hidden",
    borderRadius: 15,
    marginLeft: "auto",
    marginRight: "auto",
    color: "white",
  },
  tabs: {
    color: "white",
    marginTop: 20,
    borderRadius: 25,
    color: "white",
    backgroundColor: "transparent",
    boxShadow: "0px 0px 0px",
    zIndex: 100,
  },
  tab: {
    fontSize: "1rem",
    textTransform: "capitalize",
    borderRadius: 15,
    margin: 5,
    padding: 0,
    textShadow: "0.1em 0.1em 0.2em #333",
    border: "4px solid rgb(152, 205, 245)",
    background:
      "linear-gradient(0.25turn, rgb(24,106,202), rgb(152, 205, 245), rgb(24,106,202))",
    "& .MuiTab-wrapper": {
      fontWeight: 600,
      fontSize: 22,
      color: "white",
    },
  },
  selected: {
    fontSize: "1rem",
    borderRadius: 10,
    textTransform: "capitalize",
    textShadow: "0.1em 0.1em 0.2em #333",
    margin: 5,
    padding: 0,
    border: "4px solid #FA961D",
    background: `url(${
      require("assets/img/object.png").default
    }),linear-gradient(0.25turn, rgb(255,135,36), rgb(255, 198, 91), rgb(255,135,36))`,
    backgroundSize:"cover",
    "& .MuiTab-wrapper": {
      fontWeight: 600,
      fontSize: 22,
      color: "white",
    },
  },
}));

export default function CustomTabs({
  tabs,
  initIndex = 0,
  tabIndex,
  setTabIndex,
  noHeight,
  handleChange,
}) {
  const [value, setValue] = useState(parseInt(initIndex));
  const theme = useTheme();
  const computer = useMediaQuery(theme.breakpoints.up("lg"));
  const classes = useStyles();
  return (
    <div style={{ position: "relatisve" }}>
      <AppBar position="static" className={classes.tabs}>
        <Tabs
          TabIndicatorProps={{ style: { background: "transparent" } }}
          value={typeof tabIndex === "number" ? tabIndex : value}
          onChange={handleChange}
          indicatorColor="primary"
          style={{
            minHeight: 0,
            zIndex: 110,
            width: noHeight ? (computer ? "50%" : "100%") : "100%",
          }}
        >
          {tabs.map((tab, index) => {
            return (
              <Tab
                key={tab.label}
                style={{ minHeight: 0 }}
                className={`${
                  tabIndex == index ? classes.selected : classes.tab
                }`}
                label={tab.label}
                disabled={tab.disabled}
                {...a11yProps(index)}
              />
            );
          })}
        </Tabs>
      </AppBar>
      {tabs.map((tab, index) => {
        return (
          <TabPanel
            key={index}
            noHeight={noHeight}
            computer={computer}
            value={typeof tabIndex === "number" ? tabIndex : value}
            index={index}
          >
            {tab.content}
          </TabPanel>
        );
      })}
    </div>
  );
}
