import React from "react";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import NumberFormat from "react-number-format";
import TextField from "@material-ui/core/TextField";
import Button from "components/CustomButtons/Button";
const useStyles = makeStyles((theme) => ({
  field: {
    height: "52px",
    marginTop: 20,
    padding: 12,
    paddingTop: 0,
    borderRadius: 40,
    color: "white",
    border: "1px solid #486EA4",
    backgroundColor: "transparent",
    // boxShadow: "10px 10px 10px 1px rgba(0, 0, 0, 0.5)",
  },
  inputRoot: {
    color: "white",
    fontSize: 20,
    padding: 11,
  },
  showDetail: {
    alignItems: "center",
    marginTop: 2,
    marginBottom: 10,
    justifyContent: "space-around",
    width: "100%",
    color: "white",
    backgroundColor: "transparent",
    fontWeight: 700,
    "& .MuiInputBase-root": {
      color: "white",
    },
    "& fieldset": { color: "black" },
    "& .MuiInputBase-root.Mui-disabled": {
      backgroundColor: theme.palette.background.grey,
      color: "white",
      border: `none`,
      "& fieldset": {
        border: `none`,
      },
    },
    outline: "none",
  },
  hovered: {
    "& fieldset": {},
  },
  focused: {
    "& fieldset": {},
  },
}));

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      style={{ fontSize: "25px", padding: 9, fontWeight: 700 }}
      getInputRef={inputRef}
      allowLeadingZeros={false}
      allowNegative={false}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
    />
  );
}

export default function CustomOutlinedInput(props) {
  const commonStyle = useStyles();
  const commonClasses = {
    root: commonStyle.showDetail,
  };

  const {
    classes,
    disabled,
    setMax,
    text,
    availabletext,
    placeholder,
    button,
  } = props;
  const filteredProps = _.omit(props, ["setMax", "startAdornment"]);

  if (!setMax) {
    return (
      <div>
        <div>
          <span className="valueHeader">{text}</span>
          <span style={{ float: "right", fontWeight: 600 }}>
            {availabletext}
          </span>
        </div>
        <div className={commonStyle.field}>
          <TextField
            {...filteredProps}
            placeholder={placeholder || "0.0"}
            classes={Object.assign({}, commonClasses, classes)}
            InputProps={{
              classes: {
                input: commonStyle.inputRoot,
              },
              startAdornment: props.startAdornment,
              disableUnderline: true,
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={commonStyle.inputAdornment}
                >
                  {button}
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div className={commonStyle.field}>
          <TextField
            {...filteredProps}
            placeholder={placeholder || "0.0"}
            classes={Object.assign({}, commonClasses, classes)}
            color="secondary"
            InputProps={{
              inputComponent: NumberFormatCustom,
              startAdornment: props.startAdornment,

              disableUnderline: true,
              endAdornment: (
                <InputAdornment position="end" style={{ paddingBottom: 7 }}>
                  <Button
                    onClick={setMax}
                    color="secondary"
                    style={{ minWidth: 60, marginTop: 10 }}
                    size="sm"
                    disabled={disabled}
                  >
                    MAX
                  </Button>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div>
          <span className="valueHeader">{text}</span>
          <span style={{ float: "right", fontWeight: 600, marginTop: 15 }}>
            {availabletext}
          </span>
        </div>
      </div>
    );
  }
}

CustomOutlinedInput.defaultProps = {
  fullWidth: true,
};
