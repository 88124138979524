import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  FETCH_LEADERBOARD_DETAIL_BEGIN,
  FETCH_LEADERBOARD_DETAIL_SUCCESS,
  FETCH_LEADERBOARD_DETAIL_FAILURE,
} from "./constants";
import {
  apiUrl
} from "../../configure";
import axios from "axios";

export function fetchLeaderboardDetail({ name, search, type, page, address }) {
  return (dispatch, getState) => {
    dispatch({
      type: FETCH_LEADERBOARD_DETAIL_BEGIN,
    });

    const promise = new Promise(async (resolve, reject) => {
      try {
        let result = [];
        let userResult = [];
        if(search){
          result = await axios.get(`${apiUrl}/api/users/leaderboard?name=${name}&limit=100&sort=badges&pool=${type}&address=${search}`);
          userResult = await axios.get(`${apiUrl}/api/users/leaderboard?name=${name}&limit=100&sort=badges&pool=${type}&address=${address}`);
        }else{
          result = await axios.get(`${apiUrl}/api/users/leaderboard?name=${name}&limit=100&sort=badges&pool=${type}&page=${page}`);
          userResult = await axios.get(`${apiUrl}/api/users/leaderboard?name=${name}&limit=100&sort=badges&pool=${type}&address=${address}`);
        }
        dispatch({
          type: FETCH_LEADERBOARD_DETAIL_SUCCESS,
          data: {users: result.data,count: result.headers["x-total-count"], selfData:userResult.data},
        });
        resolve();
      }
      catch(error) {
          dispatch({
            type: FETCH_LEADERBOARD_DETAIL_FAILURE,
          });
          return reject(error.message || error);
      }
    });

    return promise;
  };
}

export function useFetchLeaderboardDetail() {
  const dispatch = useDispatch();

  const { detail, fetchLeaderboardPending } = useSelector(
    (state) => ({
      fetchLeaderboardPending: state.leaderboard.fetchLeaderboardPending,
      detail: state.leaderboard.detail,
    }),
    shallowEqual
  );

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchLeaderboardDetail(data));
    },
    [dispatch]
  );

  return {
    detail,
    fetchLeaderboardDetail: boundAction,
    fetchLeaderboardPending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case FETCH_LEADERBOARD_DETAIL_BEGIN:
      return {
        ...state,
        fetchLeaderboardPending: true,
      };

    case FETCH_LEADERBOARD_DETAIL_SUCCESS:
      return {
        ...state,
        detail: action.data,
        fetchLeaderboardPending: false,
      };

    case FETCH_LEADERBOARD_DETAIL_FAILURE:
      return {
        ...state,
        fetchLeaderboardPending: false,
      };

    default:
      return state;
  }
}
