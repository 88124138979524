import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { useConnectWallet } from "features/home/redux/hooks";
import {
  useFetchDashboard,
  useFetchPoolDetails,
  useFetchRewardPoolDetails,
} from "./redux/hooks";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useFetchPrice } from "../price/redux/hooks";
import Tabs from "components/CustomTabs/Tabs.js";

import _ from "lodash";

const useStyles = makeStyles((theme) => ({}));

const Analytics = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { fetchDashboard, detail } = useFetchDashboard();
  const { fetchPrice, priceData, lpData, ethData, chart } = useFetchPrice();
  const { setSelctedPool } = useFetchPoolDetails();
  const { rewardPoolDetails, fetchRewardPoolDetails } =
    useFetchRewardPoolDetails();
  const computer = useMediaQuery(theme.breakpoints.up("sm"));
  const { web3, address } = useConnectWallet();
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelctedPool(newValue)
    setTabValue(newValue);
  };

  useEffect(() => {
    if (web3 && !priceData) fetchPrice({ web3 });
  }, [web3]);

  useEffect(() => {
    if (web3 && address) {
      fetchDashboard({ web3, address });
      fetchRewardPoolDetails();
      const id = setInterval(() => {
        fetchDashboard({ web3, address });
        fetchRewardPoolDetails();
      }, 30000);
      return () => clearInterval(id);
    }
  }, [web3, address]);

  const renderCard = (title, value, size = 4) => {
    return (
      <Grid item xs={12} sm={size}>
        <div className="card" style={{ minHeight: 180 }}>
          <div className="cardSubHeader">{title}</div>
          <div className="cardLgValue" style={{ marginTop: 20 }}>
            {value}
          </div>
        </div>
      </Grid>
    );
  };

  return (
    <div
      style={{
        position: "relative",
        margin: "0 auto",
        paddingTop: 40,
        maxWidth: 1100,
      }}
    >
      <Tabs
        tabIndex={tabValue}
        handleChange={handleTabChange}
        tabs={[
          {
            label: "RBW",
          },
          {
            label: "RBWLP",
          },
        ]}
      />
      <Grid container spacing={1}>
        {renderCard("STAKED BY DATE", `-`, 4)}
        {renderCard("% of circulating supply", "%", 4)}
        {renderCard("REWARD DISTRIBUTED", "-", 4)}

        {renderCard("TOTAL NUMBER OF UNIQUE STAKERS", "-", 6)}
        {renderCard("TOTAL NUMBER OF ASSETS LOCKED", "-", 6)}
        {renderCard("TOTAL RBW TO EACH MONTH", "-", 12)}
      </Grid>
    </div>
  );
};

export default Analytics;
